<template>
   <span v-if="editCurrentCell==1" v-on:dblclick="editCell();">{{ form.textvalue }}</span>
   <span v-if="editCurrentCell == 2"><input ref="inputField" class="form-control" type="text" v-model="form.textvalue" v-on:blur="disableEditCell()"></span>
</template>
<script>
import { ref } from 'vue'
const inputField = ref()
export default {
 name: 'Edit Input Cell',
 props: ['dvalue'],
 data() {
     return {
        form:{textvalue: this.dvalue},
        editCurrentCell:1,
     }
    },
    methods: {
       editCell () {
         this.editCurrentCell =2
        },
        disableEditCell () {
         this.editCurrentCell =1
        }
       
    }
}
</script>