<template>
    <div class="row">
        <div class="col-md-12">
        <!-- Custom Tabs -->
            <div class="row">
                <div class="col-md-12">
                    <div class="box box-info">
                        <div class="box-header with-border">
                              <h3 class="box-title">Template List</h3>
                           </div>
                        <div class="box-body table-responsive">
                        <div class="notdatatable form-inline mbottom-15">
                            <div class="table-controls table-columns">
                                <div class="btn-group"><button ng-if="viewer.CanExport()" class="btn btn-default" type="button" ng-click="viewer.ExportExcel()"><span>Excel</span></button><button class="btn btn-default button-columns" type="button" id="Main_MyDashboard_Recent_TableControls_Visibility"><span>Column visibility</span></button></div>
                            </div>
                            <div class="table-controls table-pagesize table-columns">
                                <label>
                                    Show 
                                    <select class="form-control input-xs">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="250">250</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                    </select>
                                    entries 
                                </label>
                            </div>
                            
                            <div class="table-controls table-filter">Filter Alerts <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
                        </div>
                        <table class="table table-striped table-hover">
                            <thead>
                            <tr id="row1">
                                <th class="no-sort"  name="name" width="30"></th>
                                <th class="text-capitalize sorting">
                                    Template Name
                                </th>
                                <th class="text-capitalize sorting">
                                     Description
                                </th>
                                <th class="text-capitalize sorting">
                                    Panels That Use This Template
                                </th>
                                <th>
                                   &nbsp;
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr id="row2" @click="showMessageDetails('','row2')">
                                <td name="name" class="col-icon-check sorting_1">
                                 <div class="d-flex"> 
                                    <button class="btn btn-xs btn-link text-default" v-on:click="editrow1 = !editrow1" v-show="!editrow1">
                                       <i class="fa fa-pencil"></i>
                                    </button>
                                 </div>
                                 </td>
                                <td>
                                	
                                    <span v-show="!editrow1">
                                        Background Check
                                    </span>
                                   <div v-show="editrow1">
                                       <input type="text" class="form-control" value="Background Check">
                                    </div>
                                </td>
                                <td>
                                    <span v-show="!editrow1">
                                        The ticket goes to HR to track the completion of the background check.
                                    </span>
                                   <div v-show="editrow1">
                                       <textarea type="text" class="form-control" value="The ticket goes to HR to track the completion of the background check."></textarea>
                                    </div> 
                                </td>
                                <td>
                                    <a href="">New User Submit Form</a>
                                </td>
                                <td>
                                    <button class="btn btn-xs btn-primary" v-show="editrow1">
                                             Save
                                    </button>
                                    <button class="btn btn-xs btn-link" v-show="editrow1" v-on:click="editrow1 = !editrow1">
                                             Cancel
                                    </button>
                                </td>    
                              
                            </tr>
                            
                            </tbody>
                        </table>
                        <div id="Main_MyDashboard_Recent_TableInfo">
                            <div class="notdatatable table-info">Showing 1 to 2 of 2 entries</div>
                            <div class="dataTables_paginate notdatatable table-pagination text-end">
                                <ul class="pagination">
                                    <li><a href="">Previous</a></li>
                                    <li class="active"><a href="">1</a></li>
                                    <li><a href="">Next</a></li>
                                </ul>
                            </div>
                            </div>
                        <!-- Data loader start
                        <div class="data-loader">
                            <i class="fa fa-spinner fa-spin fa-fw"></i>
                        </div>  -->
                        <!-- Data loader end-->
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                </div>
            </div>
        </div>
        <!-- /.col -->
    </div>
   

    <div class="expandedrowcontent box-bg-color" v-if="showDetails.showNew">
        <div class="box box-info with-border" v-if="showDetails.displayformType == 'customer-submit'">
            <div class="box-body table-responsive">
                <div class="box-header with-border">
                    <h3 class="box-title text-right  mright-15 ">Initial Request Form</h3>
                    <span> <label class="control-label text-left"> 
                                <Toggle/> Enable Panel
                            </label>
                    </span>
                    <span aria-hidden="true" class="fa fa-angle-right pull-right mleft-15 chevron-icon expanded" v-bind:class = "segment1?'':'chevron-expand-indicator_expanded'"  v-on:click="toggleSegmentCollapsedState('1')"></span>
                    <span class="pull-right"><button class="btn btn-sm btn-primary">Preivew</button></span>
                </div>
                <div class="box-body"  v-bind:class = "segment1?'in collapse':''">
                    <div class="row">
                       <div class="col-md-4">
                           <div class="form-group">
                              <label class="control-label text-left">Form URL</label>
                              <div class="d-flex">
                                 <span class="text-bold">s/retrofit/submit </span>
                                 <input class="form-control small-input" type="text" id="Main_Workflow_Details_Label">
                              </div>
                              <div>
                                 <span>URL must be unique for this workflow. <br>
                                    This submit form is accessible to anonymous users at the url: <strong>s/retrofit/submit</strong>
                                </span>
                              </div>
                           </div>
                        </div>
                       <div class="col-md-4">   
                           <div class="form-group">
                              <label class="control-label text-left">Frame Type</label>
                             
                              <div>
                                 <span>  If needed, the submit form can be used in a frame either in other forms in the same tenant or from any external website.</span>
                              </div>
                              <div>
                                 <span> <strong>As best practice frame support should be disabled unless required.</strong>
                                
                                </span>
                                <div><label class="control-label text-left margin-r-5 text-red">Frame Type</label><a class="btn btn-xs margin-r-5 btn-default">Not Allowed</a><a class="btn btn-xs margin-r-5 btn-default">Same Site</a><a class="btn btn-xs margin-r-5 btn-default">Any Site</a></div>
                              </div>
                           </div>
                        </div>
                        
                        <div class="col-md-4">


                           <div class="form-group">
                            
                              <label class="control-label text-left">Custom CSS</label>
                              <div class="">
                                 <textarea class="form-control" type="text" ></textarea>
                              </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box box-info with-border" v-if=" showDetails.displayformType == 'agent-submit'">
            <div class="box-body table-responsive">
                <div class="box-header with-border">
                    <h3 class="box-title text-right  mright-15">Initial Request By Agent</h3>
                    <span> <label class="control-label text-left"> 
                                <Toggle/> Enable Panel
                            </label>
                    </span>
                    
                    <span aria-hidden="true" class="fa fa-angle-right pull-right mleft-15 chevron-icon expanded" v-bind:class = "segment1?'':'chevron-expand-indicator_expanded'"  v-on:click="toggleSegmentCollapsedState('1')"></span>
                    <span class="pull-right"><button class="btn btn-sm btn-primary">Preivew</button></span>
                </div>
                <div class="box-body"  v-bind:class = "segment1?'in collapse':''">
                    <div class="row">
                       <div class="col-md-6">
                          
                           <div class="form-group">
                              <label class="control-label text-left">Form Name in URL</label>
                              <div class="">
                                 <input class="form-control" type="text" id="Main_Workflow_Details_Label">
                              </div>
                              <div>
                                 <span>URL must be unique for this workflow. <br>
                                    This submit form is accessible to logged in agents users at the url:<br> 
                                    app/retrofit/submit/agentsubmit
                                </span>
                              </div>
                           </div>
                    </div>
                    <div class="col-md-6">
                            <div class="form-group">
                              <label class="control-label text-left">Agent Menu Link Name</label>
                             
                              <div class="">
                                 <input class="form-control" type="text">
                              </div>
                              <div>
                                 <span>This text is shown as the link to the submit form in the left side menu. 
                                </span>
                              
                           </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        </div>
        <div class="box box-info with-border" v-if=" showDetails.displayformType == 'answer-panel'">
            <div class="box-body table-responsive">
                <div class="box-header with-border">
                    <h3 class="box-title text-right  mright-15">Initial Request By Agent</h3>
                    <span> <label class="control-label text-left"> 
                                <Toggle/> Enable Panel
                            </label>
                    </span>
                    <span aria-hidden="true" class="fa fa-angle-right pull-right mleft-15 chevron-icon expanded" v-bind:class = "segment1?'':'chevron-expand-indicator_expanded'"  v-on:click="toggleSegmentCollapsedState('1')"></span>
                    <span class="pull-right"><button class="btn btn-sm btn-primary">Preivew</button></span>
                </div>
                <div class="box-body"  v-bind:class = "segment1?'in collapse':''">
                    <div class="row">
                       <div class="col-md-6">
                          
                           <div class="form-group">
                              <label class="control-label text-left">Panel Condition</label>
                              <div><label class="control-label text-left margin-r-5 text-red">Condition</label><a class="btn btn-xs margin-r-5 btn-default">Tag</a><a class="btn btn-xs margin-r-5 btn-default">Topic</a><a class="btn btn-xs margin-r-5 btn-default">Tag & Topic</a></div>
                              <div>
                                 <span>Select condition for when this panel is used
                                </span>
                              </div>
                           </div>
                    </div>
                    <div class="col-md-6">
                            <div class="form-group">
                              <label class="control-label text-left">Topic</label>
                             
                              <div class="">
                                <Multiselect
                                    v-model="value"
                                    placeholder="Select topic"
                                    label="name"
                                    :groups="true"
                                    :searchable="true"
                                    :options="[
                                        {
                                        label: 'Customer Success',
                                        options:  [
                                            { value: 'eshop all topics', name: 'e shop -- All Topics',level:'1'},
                                            { value: '_Feedback', name: '_Feedback',level:'2'},
                                            { value: '_Newsletter Subscription', name: '_Newsletter Subscription',level:'2'},
                                            { value: '_Spam', name: '_Spam',level:'2'},
                                            { value: 'Undeliverd', name: 'Undeliverd',level:'2'},
                                            { value: 'Account Questions', name: 'Account Questions',level:'1'},
                                            { value: 'Return', name: 'Return',level:'2'},
                                            { value: 'Return', name: 'Return',level:'2'},
                                            { value: 'Cancel', name: 'Cancel',level:'2'},
                                        ],
                                        }
                                    ]"
                                    >
                                    <template v-slot:option="{ option }">
                                    <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                    </template>
                                </Multiselect>
                              </div>
                              <div>
                                 <span>This text is shown as the link to the submit form in the left side menu. 
                                </span>
                              
                           </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        </div>
        <div class="row mbottom-15"> 
            <div class="col-md-12">
                <div class="box box-bg-color box-primary">
                    <div class="box-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label text-left"> Name</label>
                                    <input iservice-id="Name" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label text-left">Description</label>
                                    <textarea type="text" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label text-left"> Response Body</label>
                                    <div><label class="control-label text-left margin-r-5">Editor Type</label><a class="btn btn-xs margin-r-5 active btn-primary" >Rich Text</a><a class="btn btn-xs margin-r-5 btn-default">Plain/Source</a></div>
                                    <textarea type="text" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-if="showDetails.deleteAlert">
        <div class="col-md-12" iservice-id-prefix="'Delete'">
            <div class="box box-danger" iservice-id="'Delete'">
                <div class="box-header with-border">
                    <i class="fa fa-warning"></i>
                    <h3 class="box-title text-right">Delete Template</h3>
                </div>
                <div class="box-body">
                    <section class="box-content box-bg-color">
                    <div class="row">
                        <div class="form-horizontal">
                            <div class="col-md-12">
                                <p>All template values will be deleted.</p>
                                <p class="text-danger">WARNING: Deletion is immediate and irrevocable.</p>
                            </div>
                        </div>
                        <div class="form-horizontal">
                            <div class="col-md-12"><button iservice-id="Confirm" class="btn btn-danger btn-sm">Confirm Deletion</button><button iservice-id="Cancel" class="btn btn-link" v-on:click="cancel()">Cancel</button></div>
                        </div>
                    </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
  
   
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Toggle from '@vueform/toggle'
import InboxMessageDetail from '../components/InboxMessageDetail.vue';
import iServiceSwitch from '../components/iServiceSwitch.vue';
import NewContactPropertyForm from '../components/NewContactPropertyForm.vue';
import ContactDetail from '../components/ContactDetail.vue';

import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'
export default {
  name: 'Panel Porperty Settings',
   components: {
    InboxMessageDetail,
    iServiceSwitch,
    Multiselect,
    NewContactPropertyForm,
    Toggle,
    Multiselect,
    ContactDetail,
   
  }
  ,
    data() {
     return {
        showElements : false,
        dropElement1 : false,
        dropElement2 : false,
        dropElement3 : false,
        editrow1 : false,
        topiceditrow1 : false,
        draggedElement : '',
        segment1 : false,
        displayformType : '',
        isDragging : false
     }
    },
  methods:{
    toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    },
    startDrag(evt, item) {
      this.draggedElement = item;
    },
    onDrop(evt, item) {
      var element_id = this.draggedElement + '-element';
      var dropAreaHTMl = document.getElementById(element_id).innerHTML;
      document.getElementById("Workflow_Details_Panel0_Group0_Input"+ item +"_droparea").innerHTML =dropAreaHTMl;
      document.getElementById("tab-settings").click();
    },
    dragHover(){
        alert("");
    }
    
  },
  setup(){      
       
       var showDetails = reactive({showNew:false,deleteAlert:false, showContactDetails:false,currentRow:''});
       var showNew =   reactive(false);
       var deleteAlert =  reactive(false);
       var show = reactive(false);
       var currentRow = '';

       function showNewAlert(formtype){
       
         showDetails.displayformType = formtype;
         showDetails.showNew = true; 
         showDetails.deleteAlert = false;  
         showDetails.showContactDetails = false;
         showDetails.active_el = '';
       }
       function showDeleteAlert(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = true;  
       }
       function showMessageDetails(val,row){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showContactDetails=true;
         showDetails.active_el = row;
       }
       function cancel(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showContactDetails=false;
       }

       return{cancel,showNew,deleteAlert,showDetails,showNewAlert,showDeleteAlert,showMessageDetails};
  }

}
</script>
