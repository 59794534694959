<template>
           <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label text-left">Property Name</label
                    ><input iservice-id="Name" type="text" class="form-control"><!--v-if-->
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label text-left">Property Type</label>
                    <div>
                     <Multiselect
                            v-model="propertytype"
                            placeholder="-None-"
                            label="name"
                            :groups="false"
                            :searchable="true"
                            :options="[
                                    { value: 'Answer', name: 'Answer',level:'0'},
                                
                            ]"
                            >
                            <template  v-slot:option="{ option }">
                            <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                            </template>
                        </Multiselect>
                     </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label text-left">Value Type</label>
                    <div>
                     <Multiselect
                            v-model="valuetype"
                            placeholder="-Select Property Value Type-"
                            label="name"
                            :groups="false"
                            :searchable="true"
                            :options="[
                                    { value: 'Date', name: 'Date',level:'0'},
                                
                            ]"
                            >
                            <template  v-slot:option="{ option }">
                            <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                            </template>
                        </Multiselect>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mt-4">
                <div class="checkbox">
                    <label class="control-label text-left"> 
                            <Toggle/> Multi Line Text
                    </label>
                </div>
            </div>
            <h4 class="col-md-12">Optional Settings</h4>
            <div class="col-md-6">
               
               <div class="checkbox">
                    <label class="control-label text-left"> 
                        <Toggle/> Shared Property
                    </label>
                    
                </div>
             </div>
              <div class="col-md-6">
                <div class="checkbox">
                     <label class="control-label text-left"> 
                        <Toggle/> Allowed Multiple Value
                    </label>
                   
                </div>
                <div class="checkbox">
                        <label class="control-label text-left"> 
                                <Toggle/> Customer Veiwable
                        </label>
                    </div>
             </div>
           </div>
           <div class="row">
            <div class="col-12">
                <button iservice-id="Save" class="btn btn-primary">Save</button>
            </div> 
                <div class="col-md-12"><hr></div>
                <div class="col-md-12">
                <h4>Number of contacts with this value set: <a target="_blank" href="/app/search-contacts?w1=anywhere&amp;f1=79&amp;t1=">7658</a></h4>
                <h4> This property is used in the following locations </h4>
                <div class="row">
                    <div class="col-md-4">
                        <div class="nested-panel-heading skill-heading">
                            <h3 class="nested-title">Service Levels</h3>
                        </div>
                        <div class="skill-list"><span class="skill-item">None</span></div>
                    </div>
                </div>
                </div>

            </div>
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
    import Toggle from '@vueform/toggle'
    import Multiselect from '@vueform/multiselect'
    import iServiceSwitch from '../components/iServiceSwitch.vue';

    export default {
      name: 'Contact Property Form',
      props: ['formTitle'],
       components: {
         Multiselect,
         iServiceSwitch,
         Toggle
      },
      data: function () {
        return {
         
        }
      },
      events: {
       
      },
      methods: {
      
      }

    }
</script>