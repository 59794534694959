<template>
   <div class="row">
     <div class="box box-info">
        <div class="box-body">
           <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label text-left">Property Name</label><input iservice-id="Name" type="text" class="form-control has-error"><!--v-if-->
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label text-left">Property Type</label>
                    <div class="has-error">
                     <Multiselect
                            v-model="propertytype"
                            placeholder="-None-"
                            label="name"
                            :groups="false"
                            :searchable="true"
                            :options="[
                                    { value: 'Answer', name: 'Answer',level:'0'},
                                
                            ]"
                            >
                            <template  v-slot:option="{ option }">
                            <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                            </template>
                        </Multiselect>
                     </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label text-left">Value Type</label>
                    <div class="has-error">
                     <Multiselect
                            v-model="valuetype"
                            placeholder="-Select Property Value Type-"
                            label="name"
                            :groups="false"
                            :searchable="true"
                            :options="[
                                    { value: 'Date', name: 'Date',level:'0'},
                                
                            ]"
                            >
                            <template  v-slot:option="{ option }">
                            <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                            </template>
                        </Multiselect>
                    </div>
                </div>
            </div>
            <h4 class="col-md-12">Optional Settings</h4>
            <div class="col-md-6">
               
               <div class="checkbox">
                    <label class="control-label text-left"> 
                        <Toggle/> Shared Property
                    </label>
                    
                </div>
             </div>
              <div class="col-md-6">
                <div class="checkbox" v-bind:class=" allowmultiple ? 'group-bg group-input groupbg-checkbox' : ''">
                     <label class="control-label text-left"> 
                        <Toggle v-model="allowmultiple"/> Allowed Multiple Value
                        <div class="checkbox nested" v-if="allowmultiple">
                            <Toggle v-model="hasdescription"/> Has Description
                        </div>
                    </label>
                   
                </div>
                <div class="checkbox">
                        <label class="control-label text-left" v-bind:class="customerviewable ? 'group-bg group-input groupbg-checkbox' : ''"> 
                                <Toggle v-model="customerviewable"/> Customer Veiwable
                                 <div class="checkbox nested" v-if="customerviewable">
                                    <Toggle v-model="editable" /> Customer Editable
                                </div>
                        </label>
                    </div>
             </div>
           </div>
           <div class="row">
                <div class="col-12">
                    <button iservice-id="Create" class="btn btn-primary">Create</button>
                    <button iservice-id="Cancel" class="btn btn-link">Cancel</button>
                </div> 
            </div>
           
        </div>
      </div> 
   </div>
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
    import Toggle from '@vueform/toggle'
    import Multiselect from '@vueform/multiselect'
    import iServiceSwitch from '../components/iServiceSwitch.vue';

    export default {
      name: 'Interaction Property Form',
      props: ['formTitle'],
       components: {
         Multiselect,
         iServiceSwitch,
         Toggle,
      },
      data: function () {
        return {
           allowmultiple : false,
           customerviewable : false
        }
      },
      events: {
       
      },
      methods: {
      
      }

    }
</script>