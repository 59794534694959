<template>
     
    <span v-if="editCurrentCell==1" v-on:dblclick="editCell();">{{ form.textvalue }}</span>
    <span v-if="editCurrentCell == 2">
        <DatePicker v-model="date" mode="dateTime" :timezone="timezone">
            <template v-slot="{ inputValue, inputEvents }">
            <div>
                <div class="input-group date">
                <div class="input-group-addon" ng-click="isDatepickerOpenDelivery = !isDatepickerOpenDelivery">
                    <i class="fa fa-calendar"></i>
                </div>
                    <input
                    class="form-control pull-right"
                    :value="inputValue"
                    v-on="inputEvents"
                    />
                </div>
            </div>
            </template>
        </DatePicker>    
    
    </span>
 </template>
 <script>
 import { ref } from 'vue'
 const inputField = ref()
 export default {
  name: 'Edit Input Cell',
  props: ['dvalue'],
  data() {
      return {
         form:{textvalue: this.dvalue},
         editCurrentCell:1,
         date: new Date(),
      }
     },
     methods: {
        editCell () {
          this.editCurrentCell =2
         },
         disableEditCell () {
          this.editCurrentCell =1
         }
        
     }
 }
 </script>