<template>
   
    <div class="row">
       <div class="col-md-12">
          <div class="nav-tabs-custom">
             <ul class="nav nav-tabs">
                <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" data-bs-target="#tab_property_41" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa fa-gear"></i>  <span>Basic Setup</span></a> </li>
                <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab_property_43" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa fa-object-group"></i> <span>Panels </span></a> </li>
                <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab_property_44" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa fa-file-code-o"></i> <span>Templates </span></a> </li>
                <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab_property_47" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa fa-code-fork"></i> <span>Segment Properties</span></a> </li>
                <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab_property_45" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa fa fa-cubes"></i> <span>Workflow Properties</span></a> </li>
                <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab_property_48" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa fa-arrows-alt"></i> <span>Topics</span></a> </li>
                <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab_property_46" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa fa-upload"></i> <span>Data Files</span></a> </li>
                <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab_property_49" role="tab" aria-controls="formbody"  aria-selected="true"><i class="fa icon-c icon-support"></i> <span>Agent List</span></a> </li>
             </ul>
             <div class="tab-content">
             
                <div class="tab-pane active" id="tab_property_41">
                    <div class="row">
                        <div class="col-md-6">
                           <div class="form-group">
                              <label class="control-label text-left">Workflow Name</label>
                              <div class="">
                                 <input class="form-control" type="text" id="Main_Workflow_Details_Label">
                              </div>
                              <div>
                                 <span>Workflow Name is displayed to users and agents.</span>
                              </div>
                           </div>
                         
                        </div>
                        
                     </div>
                     <!--<div class="row"><div class="col-2"><button class="btn btn-primary margin-r-5" id="Workflow_Details_Save">Save</button></div><div class="col-md-10">v-if</div></div>-->
                </div>

                <div class="tab-pane" id="tab_property_42">
                    <div class="row">
                        <div class="col-md-12 mbottom-15">
                           <div>If needed, the submit form can be used in a frame either in other forms in the same tenant or from any external website.</div>
                           <div><b>As best practice, frame support should be disabled unless required.</b></div>
                           <span class="btn-group mright-15"><label class="control-label text-left margin-r-5">Frame Type</label><a class="btn btn-xs margin-r-5 active btn-primary" id="Workflow_Details_SubmitForm_None">Not Allowed</a><a class="btn btn-xs margin-r-5 btn-default" id="Workflow_Details_SubmitForm_Same">Same Site</a><a class="btn btn-xs margin-r-5 btn-default" id="Workflow_Details_SubmitForm_All">Any Site</a></span>
                        </div>
                        <div class="col-md-12">
                           <div class="box box-primary box-bg-color">
                              <div class="box-header with-border"><h3 class="box-title">Form Inputs</h3></div>
                              <div class="box-body">
                                   <div class="row">
                                    <div class="col-md-10">
                                          <div class="box box-info">
                                             <div class="box-body" style="position: relative;">
                                                <div class="row">
                                                   <div class="col-md-12">
                                                      <a class="btn btn-xs btn-danger margin-r-5"><i class="fa fa-times" aria-hidden="true"></i></a><!--v-if--><!--v-if-->
                                                   </div>
                                                </div>
                                                <div class="row">
                                                   <div class="col-md-12">
                                                      <div class="row">
                                                         <div class="col-md-12"><b>Email Address</b><br> Input for email addrees, and input for confirmation. </div>
                                                         <div class="col-md-4">
                                                            <div class="form-group">
                                                               <label class="control-label text-left text-red">Input ID</label>
                                                               <div class=""><input class="is-invalid form-control" type="text" id="Main_Workflow_Details_SubmitForm_Name"></div>
                                                               <div></div>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="form-group">
                                                               <label class="control-label text-left">Input Group Class</label>
                                                               <div class=""><input class="form-control" type="text" id="Main_Workflow_Details_SubmitForm_Class"></div>
                                                               <div></div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="box box-info">
                                             <div class="box-body" style="position: relative;">
                                                <div class="row">
                                                   <div class="col-md-12">
                                                      <a class="btn btn-xs btn-danger margin-r-5"><i class="fa fa-times" aria-hidden="true"></i></a><!--v-if--><a class="btn btn-xs btn-default margin-r-5"><i class="fa fa-arrow-up" aria-hidden="true"></i></a>
                                                   </div>
                                                </div>
                                                <div class="row">
                                                   <div class="col-md-12">
                                                      <div class="row">
                                                         <div class="col-md-12"><b>Topic Picker</b><br> Picker for topic, either from all topics or a preset list. </div>
                                                         <div class="col-md-4">
                                                            <div class="form-group">
                                                               <label class="control-label text-left text-red">Input ID</label>
                                                               <div class=""><input class="is-invalid form-control" type="text" id="Main_Workflow_Details_SubmitForm_Name"></div>
                                                               <div></div>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="form-group">
                                                               <label class="control-label text-left">Input Group Class</label>
                                                               <div class=""><input class="form-control" type="text" id="Main_Workflow_Details_SubmitForm_Class"></div>
                                                               <div></div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="box box-info" id="Main_Workflow_Details_SubmitForm_Input5">
                                             <div class="box-body" style="position: relative;">
                                                <div class="row">
                                                   <div class="col-md-12"><a class="btn btn-xs btn-danger margin-r-5"><i class="fa fa-times" aria-hidden="true"></i></a><a class="btn btn-xs btn-default margin-r-5"><i class="fa fa-arrow-down" aria-hidden="true"></i></a><a class="btn btn-xs btn-default margin-r-5"><i class="fa fa-arrow-up" aria-hidden="true"></i></a></div>
                                                </div>
                                                <div class="row">
                                                   <div class="col-md-12">
                                                      <div class="row">
                                                         <div class="col-md-12"><b>Question Properties</b><br> Inputs for all question properties for topic.<br><span class="btn-group mright-15"><label class="control-label text-left margin-r-5">Style</label><a class="btn btn-xs margin-r-5 btn-default" id="Main_Workflow_Details_SubmitForm_user">User Selected Topic</a><a class="btn btn-xs margin-r-5 active btn-primary" id="Main_Workflow_Details_SubmitForm_preset">Preset Topic</a></span></div>
                                                         <div class="col-md-4">
                                                            <div class="form-group">
                                                               <label class="control-label text-left text-red">Input ID</label>
                                                               <div class=""><input class="is-invalid form-control" type="text" id="Main_Workflow_Details_SubmitForm_Name"></div>
                                                               <div></div>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="form-group">
                                                               <label class="control-label text-left">Input Group Class</label>
                                                               <div class=""><input class="form-control" type="text" id="Main_Workflow_Details_SubmitForm_Class"></div>
                                                               <div></div>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="form-group">
                                                               <label class="control-label text-left text-red">Topic Name</label>
                                                               <div class=""><input class="is-invalid form-control" type="text" id="Main_Workflow_Details_SubmitForm_Input5_TopicName"></div>
                                                               <div><span>User sees properties set on named topic.</span></div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>

                                       </div>
                                      <div class="col-md-2">
                                          <div class="box box-default">
                                             <div class="box-header with-border">
                                                <h3 class="box-title">Add Input</h3>
                                             </div>
                                             <div class="box-body">
                                                <a class="btn btn-xs btn-default margin-r-5">Email Address</a><br>
                                                <a class="btn btn-xs btn-default margin-r-5">Topic Picker</a><br>
                                                <a class="btn btn-xs btn-default margin-r-5">Question Properties</a><br>
                                                <a class="btn btn-xs btn-default margin-r-5">Case Properties</a><br>
                                                <a class="btn btn-xs btn-default margin-r-5">Answer Properties</a><br>
                                                <a class="btn btn-xs btn-default margin-r-5">Contact Property</a><br>
                                                <a class="btn btn-xs btn-default margin-r-5">Single Plain Text</a><br>
                                                <a class="btn btn-xs btn-default margin-r-5">Multiline Plain Text</a><br>
                                                <a class="btn btn-xs btn-default margin-r-5">Multiline Rich Text</a><br>
                                                <a class="btn btn-xs btn-default margin-r-5">Submit Button</a><br></div>
                                          </div>
                                       </div>
                                    </div>
                              </div>
                           </div>
                           <div class="box box-primary box-bg-color">
                                 <div class="box-header with-border">
                                    <h3 class="box-title">Form Actions</h3>
                                 </div>
                                 <div class="box-body">
                                    <div class="row">
                                       <div class="col-md-10">
                                          <div class="box box-info" id="Main_Workflow_Details_SubmitForm_Action0">
                                             <div class="box-header">
                                                <h3 class="box-title">
                                                   <a class="btn btn-xs btn-danger margin-r-5"><i class="fa fa-times" aria-hidden="true"></i></a><!--v-if--><!--v-if--> Customer Ticket
                                                </h3>
                                             </div>
                                             <div class="box-body">
                                                <div class="row">
                                                   <div class="col-md-12">
                                                      <div class="row">
                                                         <div class="col-md-12"> User enters their email address and a Customer Ticket is created. </div>
                                                         <div class="col-md-4">
                                                            <div class="form-group">
                                                               <label class="control-label text-left">Destination Email Input ID</label>
                                                               <div class=""><input class="form-control" type="text" id="Main_Workflow_Details_SubmitForm_Action0_DestEmail"></div>
                                                               <div>Email Address is entered by user in the named Input.</div>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="form-group">
                                                               <label class="control-label text-left text-red">Topic Input Name</label>
                                                               <div class="">
                                                                  <select class="form-control" style="width: 100%; border-color: red;">
                                                                     <option value="Body">Body</option>
                                                                     <option value="Email Address">Email Address</option>
                                                                     <option value="Subject">Subject</option>
                                                                     <option value="Submit">Submit</option>
                                                                     <option value="Topic">Topic</option>
                                                                  </select>
                                                               </div>
                                                               <div><span class="btn-group mright-15"><label class="control-label text-left margin-r-5">Style</label><a class="btn btn-xs margin-r-5 active btn-primary" id="Main_Workflow_Details_user">Chosen by User</a><a class="btn btn-xs margin-r-5 btn-default" id="Main_Workflow_Details_preset">Preset Topic</a></span><br><span>Ticket created in topic chosen by user in the specified Input.</span></div>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="form-group">
                                                               <label class="control-label text-left text-red">Subject Input ID</label>
                                                               <div class="">
                                                                  <select class="form-control" style="width: 100%; border-color: red;">
                                                                     <option value="Body">Body</option>
                                                                     <option value="Email Address">Email Address</option>
                                                                     <option value="Subject">Subject</option>
                                                                     <option value="Submit">Submit</option>
                                                                     <option value="Topic">Topic</option>
                                                                  </select>
                                                               </div>
                                                               <div><span class="btn-group mright-15"><label class="control-label text-left margin-r-5">Style</label><a class="btn btn-xs margin-r-5 active btn-primary" id="Main_Workflow_Details_user">Entered by User</a><a class="btn btn-xs margin-r-5 btn-default" id="Main_Workflow_Details_preset">Preset Subject</a></span><br><span>Ticket subject is entered by user in the specified Input.</span></div>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="form-group">
                                                               <label class="control-label text-left">Question Properties</label>
                                                               <div class="">
                                                                  <select class="form-control" style="width: 100%;">
                                                                     <option value="">-- None --</option>
                                                                  </select>
                                                               </div>
                                                               <div><span>Question properties are entered by user in the specified Input.</span></div>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-4">
                                                            <div class="form-group">
                                                               <label class="control-label text-left">Case Properties</label>
                                                               <div class="">
                                                                  <select class="form-control" style="width: 100%;">
                                                                     <option value="">-- None --</option>
                                                                  </select>
                                                               </div>
                                                               <div><span>Case properties are entered by user in the specified Input.</span></div>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-12">
                                                            <div class="row">
                                                               <div class="col-md-4">
                                                                  <b>Ticket Body</b><br><span class="btn-group mright-15"><label class="control-label text-left margin-r-5">Style</label><a class="btn btn-xs margin-r-5 active btn-primary" id="Main_Workflow_Details_user">Entered by User</a><a class="btn btn-xs margin-r-5 btn-default" id="Main_Workflow_Details_preset">Preset Body</a></span><br><span>Ticket body is entered by user in the specified Input.</span><!--v-if-->
                                                               </div>
                                                               <div class="col-md-4">
                                                                  <div class="form-group">
                                                                     <label class="control-label text-left text-red">Interaction Body Input</label>
                                                                     <div class="">
                                                                        <select class="form-control" style="width: 100%; border-color: red;">
                                                                           <option value="Body">Body</option>
                                                                           <option value="Email Address">Email Address</option>
                                                                           <option value="Subject">Subject</option>
                                                                           <option value="Submit">Submit</option>
                                                                           <option value="Topic">Topic</option>
                                                                        </select>
                                                                     </div>
                                                                     <div></div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="col-md-2">
                                          <div class="box box-default" id="Main_Workflow_Details_SubmitForm_Add">
                                             <div class="box-header with-border">
                                                <h3 class="box-title">Add Action</h3>
                                             </div>
                                             <div class="box-body"><a class="btn btn-xs btn-default margin-r-5" id="Main_Workflow_Details_SubmitForm_Add_CustomerTicket">Customer Ticket</a><br><a class="btn btn-xs btn-default margin-r-5" id="Main_Workflow_Details_SubmitForm_Add_AgentEmail">Email To Customer</a><br><a class="btn btn-xs btn-default margin-r-5" id="Main_Workflow_Details_SubmitForm_Add_ContactProps">Set Contact Properties</a><br></div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                        </div>
                   </div>
                </div>
                <div class="tab-pane" id="tab_property_43">
                 <panelSettings></panelSettings>
                </div>
                <div class="tab-pane" id="tab_property_44">
                  <div class="row">
                     <div class="col-md-12 mbottom-15">
                         <WorkflowTemplateSettings></WorkflowTemplateSettings>
                     </div> 
                  </div>
                </div>
                <div class="tab-pane" id="tab_property_45">
                     <WorkflowProperties></WorkflowProperties>
                </div>
                <div class="tab-pane" id="tab_property_46">
                  <div class="row  mtop-15">
                     <div class="col-2"  v-if="step == 'createfile'">
                        <button class="btn btn-primary" v-on:click=" step = 'createfilename'">Create File Definition</button>
                     </div>
                  </div>
                  <div class="row" v-if="step == 'createfilename'">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label text-left">Table Name</label>
                            <input iservice-id="Name" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                           <button class="btn btn-primary  mtop-20" type="button" title="Next" v-on:click=" step = 'fileDefinition'">Next</button>
                        </div>
                    </div>
                    
                  </div>
                  <div v-if="step == 'fileDefinition'">
                     <div class="row">
                        <div class="col-md-4">
                           <label class="control-label text-left">Table Name</label>
                           <input type="text" disabled class="form-control" value="Samplefilename"> 
                        </div>
                        <div class="col-md-4">
                           <div class="form-group">
                              <button class="btn btn-danger mtop-20" type="button" title="Remove Table">Remove File Definition</button>
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <button class="btn btn-lg btn-link" type="button">
                              <i class="fa fa-plus-circle text-light-blue"></i> Add Column Definition
                        </button>
                     </div>
                     <div class="row">
                        <div class="col-md-4">
                              <div class="form-group"> 
                                 <label class="control-label text-left">Label</label>
                              </div>
                        </div>
                        <div class="col-md-4">
                              <div class="form-group">
                                 
                                 <label class="control-label text-left">Type</label>
                              </div>
                        </div>
                       
                     </div>
                     <div class="row">
                        <div class="col-md-4">
                              <div class="form-group">
                                 <input type="text" class="form-control">
                              </div>
                        </div>
                        <div class="col-md-4">
                              <div class="form-group">
                                 <select class="form-control">
                                    <option>Number</option>                               
                                 </select>
                              </div>
                        </div>
                       
                        <div class="col-md-1">
                              <button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-md-4">
                           <div class="form-group">
                              <input type="text" class="form-control">
                           </div>
                     </div>
                     <div class="col-md-4">
                           <div class="form-group">
                              <select class="form-control">
                                 <option>Text</option>                               
                              </select>
                           </div>
                     </div>
                   
                     <div class="col-md-1">
                           <button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button>
                     </div>
                     </div>
                     <div class="row">
                        <div class="col-2">
                           <button class="btn btn-default mright-5" v-on:click="step = 'createfilename'">Back</button>
                           <button class="btn btn-primary" v-on:click="step = 'uploadfile'">Create</button>
                        </div>
                     </div>
                  </div>
                  <div  v-if="step == 'uploadfile'">
                     <div class="row">
                           <div class="col-md-4">
                              <label class="control-label text-left">Table Name</label>
                              <input type="text" disabled class="form-control" value="Samplefilename"> 
                           </div>
                           <div class="col-md-4">
                              <div class="form-group">
                                 
                                 <button class="btn btn-danger mtop-20" type="button" title="Remove Table">Remove File Definition</button>
                              </div>
                           </div>
                        </div>
                     <div class="row" >
                        <div class="col-md-6">
                              <label class="form-label" for="customFile">Upload file</label>
                              <div class="d-flex">
                              <input type="file" class="form-control" id="customFile" /> 
                              <button class="btn btn-primary" v-on:click="substep = 'tablepreview'">Upload</button>
                              </div>
                        </div>
                     </div>
                   
                     <div class="row" v-if="substep == 'tablepreview'">
                        <div class="col-md-12">
                              <h3>Table Preview
                                 </h3>
                              <div class="estimator-row mtop-15"> 						
                              <div class="p-datatable p-component p-datatable-responsive-scroll">
                              <div class="p-datatable-wrapper table-responsive">
                                 <table class="p-datatable-table editable-cells-table">
                                    <thead class="p-datatable-thead">
                                       <tr>
                                          <th class="s-icon-col">&nbsp;</th>
                                          <th>Material</th>
                                          <th>Price</th>
                                          <th>SKU</th>
                                          <th>Date</th>
                                       </tr>
                                    </thead>
                                    <tbody class="p-datatable-tbody">
                                    
                                          <tr>
                                             <td class="s-icon-col">
                                                <button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button>
                                             </td>
                                             <td><EditableInpComponent dvalue="Demot"></EditableInpComponent></td>
                                             <td><EditableInpComponent dvalue="$600"></EditableInpComponent></td>
                                             <td><EditableInpComponent dvalue="Ufoop"></EditableInpComponent></td>
                                             <td><EditableInpDateComponent dvalue="10/11/2023"></EditableInpDateComponent></td>
                                          </tr> 
                                          <tr> 
                                             <td class="s-icon-col">
                                                <button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button>
                                             </td>
                                             <td><EditableInpComponent dvalue="Option 1"></EditableInpComponent></td>
                                             <td><EditableInpComponent dvalue="$300"></EditableInpComponent></td>
                                             <td><EditableInpComponent dvalue="U89"></EditableInpComponent></td>
                                             <td><EditableInpDateComponent dvalue="10/11/2023"></EditableInpDateComponent></td>
                                          </tr> 
                                          <tr>
                                             <td class="s-icon-col">
                                                <button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button>
                                             </td>
                                             <td><EditableInpComponent dvalue="Option 2"></EditableInpComponent></td>
                                             <td><EditableInpComponent dvalue="$500"></EditableInpComponent></td>
                                             <td><EditableInpComponent dvalue="X8989"></EditableInpComponent></td>
                                             <td><EditableInpDateComponent dvalue="10/11/2023"></EditableInpDateComponent></td>
                                          </tr> 
                                          <tr>
                                             <td class="s-icon-col">
                                                <button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button>
                                             </td>
                                             <td><EditableInpComponent dvalue="Option3"></EditableInpComponent></td>
                                             <td><EditableInpComponent dvalue="$200"></EditableInpComponent> </td>
                                             <td><EditableInpComponent dvalue="U00"></EditableInpComponent> </td>
                                             <td><EditableInpDateComponent dvalue="10/11/2023"></EditableInpDateComponent></td>
                                          </tr>                            
                                       </tbody>
                                    </table>
                              </div>
                              </div>
                           </div>
                        </div>
                      
                      </div>

                      <div class="row">
                        <div class="col-2 mtop-15">
                           <button class="btn btn-default mright-5" v-on:click="step = 'fileDefinition'">Back</button>
                           
                        </div>
                     </div>
                  </div>
                  <div v-if="step =='created'">
                        <div class="row">
                           <div class="col-md-4">
                              <label class="control-label text-left">Table Name</label>
                              <input type="text" disabled class="form-control" value="Samplefilename"> 
                           </div>
                           <div class="col-md-4">
                              <div class="form-group">
                                 <button class="btn btn-danger mtop-20" type="button" title="Remove Table">Remove File Definition</button>
                              </div>
                           </div>
                        </div>
                        
                        <div class="box box-primary">
                           <div class="box-header with-border">
                              <h3 class="box-title head-title-padding">
                                 Table Column Definition
                              </h3>
                              <span aria-hidden="true" class="fa fa-angle-right pull-right mleft-15 chevron-icon expanded" v-bind:class = "segment1?'':'chevron-expand-indicator_expanded'"  v-on:click="toggleSegmentCollapsedState('1')"></span>
                           </div>
         
                           <div class="box-body" v-bind:class = "segment1?'in collapse':''">
                              <div class="row">
                                 <button class="btn btn-lg btn-link" type="button" v-on:click=" tabledefinationedit=1;">
                                       <i class="fa fa-plus-circle text-light-blue"></i> Add Column Definition
                                 </button>
                              </div>
                              
                              <div class="row" v-if="tabledefinationedit == 1">
                                 <div class="col-md-12">
                                    <div class="box box-danger">
                                       <div class="box-header with-border">
                                          <i class="fa fa-warning"></i>
                                          <h3 class="box-title text-right">Update Column Definition</h3>
                                       </div>
                                       <div class="box-body">
                                          <section class="box-content box-bg-color">
                                             <div class="row mtop-15">
                                                <div class="form-horizontal">
                                                   <div class="col-md-12">
                                                    <p class="text-danger">WARNING:Updates in file definition will delete old data, Do you want to proceed?</p>
                                                   </div>
                                                </div>
                                                <div class="form-horizontal">
                                                   <div class="col-md-12"><button class="btn btn-danger" v-on:click=" tabledefinationedit=2;proceedrow=1;uploadfile=1">Proceed</button><button class="btn btn-link" v-on:click=" tabledefinationedit=2;">Cancel</button></div>
                                                </div>
                                             </div>
                                          </section>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div >
                                 <div class="row">
                                    <div class="col-md-4">
                                          <div class="form-group">
                                          
                                          <label class="control-label text-left">Label</label>
                                          </div>
                                    </div>
                                    <div class="col-md-4">
                                          <div class="form-group">
                                             <label class="control-label text-left">Type</label>
                                          </div>
                                    </div>
                                 </div>

                                 <div class="row">
                                    <div class="col-md-4">
                                          <div class="form-group">
                                             <input type="text" class="form-control">
                                          </div>
                                    </div>
                                    <div class="col-md-4">
                                          <div class="form-group">
                                             <select class="form-control">
                                                <option>Number</option>                               
                                             </select>
                                          </div>
                                    </div>
                                  
                                    <div class="col-md-1">
                                          <button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button>
                                    </div>
                                 </div>
                                 <div class="row">
                                       <div class="col-md-4">
                                             <div class="form-group">
                                                <input type="text" class="form-control">
                                             </div>
                                       </div>
                                       <div class="col-md-4">
                                             <div class="form-group">
                                                <select class="form-control">
                                                   <option>Text</option>                               
                                                </select>
                                             </div>
                                       </div>
                                      
                                       <div class="col-md-1">
                                             <button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button>
                                       </div>
                                 </div>
                                 <div class="row" v-if="proceedrow==1" >
                                       <div class="col-md-1">
                                             <div class="form-group">
                                                <label class="control-label text-left">Col3</label>
                                             </div>
                                       </div>
                                       <div class="col-md-4">
                                             <div class="form-group">
                                                <select class="form-control">
                                                   <option>Text</option>                               
                                                </select>
                                             </div>
                                       </div>
                                       <div class="col-md-4">
                                             <div class="form-group">
                                                <input type="text" class="form-control">
                                             </div>
                                       </div>
                                       <div class="col-md-1">
                                             <button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button>
                                       </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        
                       
                        <div class="row">
                           <div class="col-md-12">
                              <h3>Table Uploaded
                                 <span class="text-primary mleft-15 cursor-pointer" v-on:click=" uploadfile=2; uploadfilealert=1"> <i class="fa fa-upload"></i>Upload New File</span>
                              </h3>
                              <div class="row" v-if="uploadfilealert == 1">
                                 <div class="col-md-12">
                                    <div class="box box-danger">
                                       <div class="box-header with-border">
                                          <i class="fa fa-warning"></i>
                                          <h3 class="box-title text-right">Upload New File</h3>
                                       </div>
                                       <div class="box-body">
                                          <section class="box-content box-bg-color">
                                             <div class="row mtop-15">
                                                <div class="form-horizontal">
                                                   <div class="col-md-12">
                                                    <p class="text-danger">WARNING:Uploading new file will delete old data, Do you want to proceed?</p>
                                                   </div>
                                                </div>
                                                <div class="form-horizontal">
                                                   <div class="col-md-12"><button class="btn btn-danger" v-on:click=" uploadfile=1; uploadfilealert=2">Proceed</button><button class="btn btn-link" v-on:click=" uploadfile=2; uploadfilealert=2">Cancel</button></div>
                                                </div>
                                             </div>
                                          </section>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="row" v-if="uploadfile == 1">
                                 <div class="col-md-6">
                                       <label class="form-label" for="customFile">Upload file</label>
                                       <div class="d-flex">
                                       <input type="file" class="form-control" id="customFile" /> 
                                          <button class="btn btn-primary" v-on:click="substep = 'tablepreview'">Upload</button>
                                          <button class="btn btn-link" v-on:click=" uploadfile=2; uploadfilealert=2">Cancel</button>

                                       </div>
                                 </div>
                              </div>
                              <div class="estimator-row mtop-15" v-if=" uploadfile !=1 && uploadfilealert !=1"> 						
                              <div class="p-datatable p-component p-datatable-responsive-scroll">
                              <div class="p-datatable-wrapper table-responsive">
                                    <table class="p-datatable-table editable-cells-table">
                                       <thead class="p-datatable-thead">
                                       <tr>
                                          <th class="s-icon-col">&nbsp;</th>
                                          <th>Material</th>
                                          <th>Price</th>
                                          <th>SKU</th>
                                          <th>Date</th>
                                          
                                       </tr>
                                    </thead>
                                    <tbody class="p-datatable-tbody">
                                          <tr>
                                             <td class="s-icon-col">
                                                <button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button>
                                             </td>
                                             <td><EditableInpComponent dvalue="Demot"></EditableInpComponent></td>
                                             <td><EditableInpComponent dvalue="$600"></EditableInpComponent></td>
                                             <td><EditableInpComponent dvalue="Ufoop"></EditableInpComponent></td>
                                             <td><EditableInpDateComponent dvalue="10/11/2023"></EditableInpDateComponent></td>
                                          </tr> 
                                          <tr> 
                                             <td class="s-icon-col">
                                                <button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button>
                                             </td>
                                             <td><EditableInpComponent dvalue="Option 1"></EditableInpComponent></td>
                                             <td><EditableInpComponent dvalue="$300"></EditableInpComponent></td>
                                             <td><EditableInpComponent dvalue="U89"></EditableInpComponent></td>
                                             <td><EditableInpDateComponent dvalue="10/11/2023"></EditableInpDateComponent></td>
                                          </tr> 
                                          <tr>
                                             <td class="s-icon-col">
                                                <button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button>
                                             </td>
                                             <td><EditableInpComponent dvalue="Option 2"></EditableInpComponent></td>
                                             <td><EditableInpComponent dvalue="$500"></EditableInpComponent></td>
                                             <td><EditableInpComponent dvalue="X8989"></EditableInpComponent></td>
                                             <td><EditableInpDateComponent dvalue="10/11/2023"></EditableInpDateComponent></td>
                                          </tr> 
                                          <tr>
                                             <td class="s-icon-col">
                                                <button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button>
                                             </td>
                                             <td><EditableInpComponent dvalue="Option3"></EditableInpComponent></td>
                                             <td><EditableInpComponent dvalue="$200"></EditableInpComponent> </td>
                                             <td><EditableInpComponent dvalue="U00"></EditableInpComponent> </td>
                                             <td><EditableInpDateComponent dvalue="10/11/2023"></EditableInpDateComponent></td>
                                          </tr>                            
                                       </tbody>
                                    </table>
                              </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-2 mtop-15" v-if=" uploadfile ==1">
                           <button class="btn btn-primary" v-on:click=" step = 'created'">Save</button>
                        </div>
                        </div>
                        

                     </div>
                </div>

                <div class="tab-pane" id="tab_property_47">
                    <div class="row">
                     <div>
                        <table class="table table-striped">
                        <thead>
                           <tr role="row">
                                 <th class="no-sort" name="name" tabindex="0" aria-controls="historyTabCaseView" rowspan="1" colspan="1"></th>
                                 <th class="sorting">
                                    Display Name
                                 </th>
                              
                                 <th class="sorting">
                                    Type
                                 </th>
                           
                                 <th class="sorting">
                                    Segment Topic Mapping
                                 </th>
                                 <th class="sorting">
                                    Panels That Use This Properties
                                 </th>
                           </tr>
                        </thead>
                           
                           <tbody>
                           <tr role="row">
                                 <td name="name" class="col-icon-check sorting_1">
                                 <div class="d-flex"> 
                                    <button class="btn btn-xs btn-link text-default" v-on:click="editrow1 = !editrow1" v-show="!editrow1">
                                       <i class="fa fa-pencil"></i>
                                    </button>
                                 </div>
                                 </td>
                                 <td>
                                    <span v-show="!editrow1">
                                        City
                                    </span>
                                   <div v-show="editrow1">
                                       <input type="text" class="form-control">
                                    </div>
                                 </td>
                                 <td>
                                   Contact
                                 </td>
                                 <td  style="width: 210px;">
                                    <span v-show="!editrow1">
                                       All Topic
                                    </span>
                                    <div v-show="editrow1">
                                       <Multiselect
                                    v-model="value"
                                    placeholder="Select topic"
                                    label="name"
                                    :groups="true"
                                    :searchable="true"
                                    :options="[
                                        {
                                        label: 'Customer Success',
                                        options:  [
                                            { value: 'eshop all topics', name: 'e shop -- All Topics',level:'1'},
                                            { value: '_Feedback', name: '_Feedback',level:'2'},
                                            { value: '_Newsletter Subscription', name: '_Newsletter Subscription',level:'2'},
                                            { value: '_Spam', name: '_Spam',level:'2'},
                                            { value: 'Undeliverd', name: 'Undeliverd',level:'2'},
                                            { value: 'Account Questions', name: 'Account Questions',level:'1'},
                                            { value: 'Return', name: 'Return',level:'2'},
                                            { value: 'Return', name: 'Return',level:'2'},
                                            { value: 'Cancel', name: 'Cancel',level:'2'},
                                        ],
                                        }
                                    ]"
                                    >
                                    <template v-slot:option="{ option }">
                                    <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                    </template>
                                </Multiselect>
                                    </div>
                                 </td>
                                 <td>
                                    <span href="" v-show="!editrow1"> <a href="">Test Panel </a></span>
                                     <div v-show="editrow1">
                                       <a href="">Test Panel </a>
                                    </div>
                                 </td>
                                 <td>
                                    <button class="btn btn-xs btn-primary" v-show="editrow1">
                                             Save
                                    </button>
                                    <button class="btn btn-xs btn-link" v-show="editrow1" v-on:click="editrow1 = !editrow1">
                                             Cancel
                                    </button>
                                 </td>
                                 
                           </tr>
                        </tbody>
                        </table>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="tab_property_48">
                    <div class="row">
                     <div>
                        <table class="table table-striped">
                        <thead>
                           <tr role="row">
                                 <th class="no-sort" name="name" tabindex="0" aria-controls="historyTabCaseView" rowspan="1" colspan="1"></th>
                                 <th class="sorting">
                                    Display Name
                                 </th>
                                 <th class="sorting">
                                    Segment Topic Mapping
                                 </th>
                                 <th class="sorting">
                                    Used In
                                 </th>
                           </tr>
                        </thead>
                           
                           <tbody>
                           <tr role="row">
                                 <td name="name" class="col-icon-check sorting_1">
                                 <div class="d-flex"> 
                                    <button class="btn btn-xs btn-link text-default" v-on:click="topiceditrow1 = !topiceditrow1" v-show="!topiceditrow1">
                                       <i class="fa fa-pencil"></i>
                                    </button>
                                 </div>
                                 </td>
                                 <td>
                                    <span v-show="!topiceditrow1">
                                        Request a Quote
                                    </span>
                                   <div v-show="topiceditrow1">
                                     <input type="text" value="Request a Quote">
                                    </div>
                                 </td>
                               
                                
                                 <td>
                                    <span href="" v-show="!topiceditrow1">Request a Quote</span>
                                     <div v-show="topiceditrow1">
                                       <select class="form-control">
                                         <option>Request a Quote</option>
                                       </select>
                                    </div>
                                 </td>
                                 <td>
                                    <span href="" v-show="!topiceditrow1">Test Panel</span>
                                     <div v-show="topiceditrow1">
                                       Test Panel
                                    </div>
                                 </td>
                                 <td>
                                    <button class="btn btn-xs btn-primary" v-show="topiceditrow1">
                                             Save
                                    </button>
                                    <button class="btn btn-xs btn-link" v-show="topiceditrow1" v-on:click="topiceditrow1 = !topiceditrow1">
                                             Cancel
                                    </button>
                                 </td>
                                 
                           </tr>
                              
                           

                             
                        </tbody>
                        </table>
                        </div>
                    </div>
                </div>

                <div class="tab-pane" id="tab_property_49">
                     <WorkflowAgentList></WorkflowAgentList>
                </div>
             </div>
          </div>
       </div>
    </div>
    <div class="row">
                        <div class="col-2 mtop-15">
                           
                           <button class="btn btn-primary" v-on:click=" step = 'created'">Save</button>
                        </div>
                     </div>


<div id="html-element" style="display:none">
   <div class="" style="border: 2px solid burlywood !important; padding:5px">
      <div class="row">
         <div class="col-md-12">
            <div>hello </div>
         </div>
      </div>
   </div>
</div>

<div id="buttons-element" style="display:none">
   <div class="form-group">
      <div class="btn-group margin-r-5" id="Workflow_Details_Panel0_Group0_Input1_Buttons_0">
         <button class="btn btn-primary" style="border-color: burlywood !important; border-width: 5px;">
            <!----> Button Sample
         </button>
         <!---->
      </div>
      <div class="btn-group margin-r-5">
         <button class="btn btn-xs btn-default">
            <i class="fa fa-plus"></i> Add<!---->
         </button>
         &nbsp; 
      </div>
   </div>
</div>

<div id="standardanswer-element" style="display:none">
   <div style="border:5px solid burlywood !important">
   <div class="box box-success" id="Workflow_Details_Panel2_Group0_Input0_Buttons_Edit_Action0">
      <div class="box-body">
         <div>
            <a class="btn btn-xs btn-danger margin-r-5"><i class="fa fa-times" aria-hidden="true"></i></a><!--v-if--><a class="btn btn-xs btn-default margin-r-5"><i class="fa fa-arrow-down" aria-hidden="true"></i></a>
            <div class="row">
               <div class="col-md-12"><b>Show Note Panel</b><br><span>The create note panel is presented to user to create a note in the case.</span></div>
               <div class="col-md-4">
                  <div class="form-group">
                     <label class="control-label text-left">Cancel Label</label>
                     <div class="" style="position: relative;"><input class="form-control" type="text" id="Workflow_Details_Panel6_Group0_Input1_Buttons_Edit_Action0_Cancel"></div>
                     <div>
                        <div class="validator-message">
                           <div class="is-invalid"></div>
                           <!--v-if-->
                        </div>
                        This label is used in the panel on link to return to the workflow answer panel. 
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</div>
 
 </template>
 
 <script>
 import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';
 import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
 import CustomerPortalList from '../components/CustomerPortalList.vue';
 import DashbordConfig from '../components/DashbordConfig.vue';
 import EditableInpComponent from '../components/EditableInpComponent.vue';
 import EditableInpDateComponent from '../components/EditableInpDateComponent.vue';
 import panelSettings from '../components/panelSettings.vue';
 import WorkflowTemplateSettings from '../components/WorkflowTemplateSettings.vue';
 import WorkflowProperties from '../components/WorkflowProperties.vue';
 import WorkflowAgentList from '../components/WorkflowAgentList.vue';
 
 import Toggle from '@vueform/toggle'
 import Multiselect from '@vueform/multiselect'
 import { ColorPicker } from "vue-accessible-color-picker";
   export default {
   name: 'Workflow Form',
    components: {
       DetailsandattachmentsTabContent,
       InteractionPropertyTab,
       Multiselect,
       CustomerPortalList,
       ColorPicker,
       DashbordConfig,
       Toggle,
       EditableInpComponent,
       EditableInpDateComponent,
       panelSettings,
       WorkflowTemplateSettings,
       WorkflowProperties,
       WorkflowAgentList
   },
   mounted(){
           
       },
    data() {
     return {
        btnoption: 'tagged',
        color: 'black',
        color1: 'black',
        colpicker1 :false,
        colpicker2 : false ,
        color3: 'black',
        color4: 'black',
        colpicker3 :false,
        colpicker4 : false ,
        showDetails :false,
        showAlert:false,
        createNew:false,
        theme:'theme1',
        wordpressSettings:false,
        independentSettings:false,
        websiteSettings:false,
        wholetopiclist:false,
        singletopic:false,
        multipletopic:false,
        step:'createfile',
        substep : '',
        editrow1 : 1,
        editrowd1 : 2,
        editrow2 : 1,
        editrowd2 : 2,
        editrow3 : 1,
        editrowd3 : 2,
        editrow4 : 1,
        editrowd4 : 2,
        segment1 : true,
        uploadfile:2,
        uploadfilealert:2,
        tabledefinationedit:2,
        proceedrow : 2,
        showElements : false,
        dropElement1 : false,
        dropElement2 : false,
        dropElement3 : false,
        editrow1 : false,
        topiceditrow1 : false,
        draggedElement : ''
     }
   },
 
   methods: {
     updateColor (eventData) {
       this.color = eventData.cssColor
     },
     updateColor1 (eventData) {
       this.color1 = eventData.cssColor
     },
     updateColor3 (eventData) {
       this.color3 = eventData.cssColor
     },
     updateColor4 (eventData) {
       this.color4 = eventData.cssColor
     },
     getImgUrl2(theme) {
        return require('../assets/theme/chat/'+theme+'.png')
     },
     toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    },

    startDrag(evt, item) {
      this.draggedElement = item;
    },
    onDrop(evt, item) {
      var element_id = this.draggedElement + '-element';
      var dropAreaHTMl = document.getElementById(element_id).innerHTML;
      document.getElementById("Workflow_Details_Panel0_Group0_Input0_droparea").innerHTML =dropAreaHTMl;
      document.getElementById("tab-settings").click();
    },
   },
   mounted() { 
     this.emitter.on("show-message-details", Data => {
       this.showDetails = Data.showDetails;
       this.createNew = false;
     });
   }
 
 }
 
 </script>